import {
  almuerzo1,
  almuerzo10,
  almuerzo11,
  almuerzo12,
  almuerzo13,
  almuerzo14,
  almuerzo2,
  almuerzo3,
  almuerzo4,
  almuerzo5,
  almuerzo6,
  almuerzo7,
  almuerzo8,
  almuerzo9,
  ImgActivities1,
  ImgActivities2,
  ImgActivities3,
  ImgActivities4,
  ImgActivities5,
  ImgActivities6,
  ImgActivities7,
  ImgActivities8,
  ImgActivities9,
  ImgBlankImage,
  ImgCampusAdministrators,
  ImgFatherDay1,
  ImgFatherDay10,
  ImgFatherDay2,
  ImgFatherDay3,
  ImgFatherDay4,
  ImgFatherDay5,
  ImgFatherDay6,
  ImgFatherDay7,
  ImgFatherDay8,
  ImgFatherDay9,
  ImgImposicion1,
  ImgImposicion2,
  ImgImposicion3,
  ImgImposicion4,
  ImgImposicion5,
  ImgImposicion6,
  ImgImposicion7,
  ImgImposicion8,
  ImgMotherDay1,
  ImgMotherDay10,
  ImgMotherDay11,
  ImgMotherDay12,
  ImgMotherDay13,
  ImgMotherDay2,
  ImgMotherDay3,
  ImgMotherDay4,
  ImgMotherDay5,
  ImgMotherDay6,
  ImgMotherDay7,
  ImgMotherDay8,
  ImgMotherDay9,
  ImgSocios1,
  ImgSocios10,
  ImgSocios2,
  ImgSocios3,
  ImgSocios4,
  ImgSocios5,
  ImgSocios6,
  ImgSocios7,
  ImgSocios8,
  ImgSocios9,
  ImgWorks1,
  ImgWorks2, Swearing1, Swearing2, Swearing3, Swearing4, Swearing5, Swearing6, Swearing7, Swearing8, Swearing9
} from "../images";

export const flipBookList = [
  {
    type: "mother-day",
    images: [ImgMotherDay1, ImgMotherDay2, ImgMotherDay3, ImgMotherDay4, ImgMotherDay5, ImgMotherDay6, ImgMotherDay7, ImgMotherDay8, ImgMotherDay9, ImgMotherDay10, ImgMotherDay11, ImgMotherDay12, ImgMotherDay13, ImgCampusAdministrators],
  },
  {
    type: "fatherDay",
    images: [
      ImgFatherDay1,
      ImgFatherDay2,
      ImgFatherDay3,
      ImgFatherDay4,
      ImgFatherDay5,
      ImgFatherDay6,
      ImgFatherDay7,
      ImgFatherDay8,
      ImgFatherDay9,
      ImgFatherDay10,
    ],
  },

  {
    type: "eventDeportivo",
    images: [
      ImgSocios1,
      ImgSocios2,
      ImgSocios3,
      ImgSocios4,
      ImgSocios5,
      ImgSocios6,
      ImgSocios7,
      ImgSocios8,
      ImgSocios9,
      ImgSocios10,
    ],
  },
  {
    type: "imposicionMedalla",
    images: [
      ImgImposicion1,
      ImgImposicion2,
      ImgImposicion3,
      ImgImposicion4,
      ImgImposicion5,
      ImgImposicion6,
      ImgImposicion7,
      ImgImposicion8,
    ],
  },
  {
    type: "NationalHolidays",
    images: [
      almuerzo1,
      almuerzo2,
      almuerzo3,
      almuerzo4,
      almuerzo5,
      almuerzo6,
      almuerzo7,
      almuerzo8,
      almuerzo9,
      almuerzo10,
      almuerzo11,
      almuerzo12,
      almuerzo13,
      almuerzo14,
    ],
  },{
    type: "swearing-military-circle",
    images: [
      Swearing1,
      Swearing2,
      Swearing3,
      Swearing4,
      Swearing5,
      Swearing6,
      Swearing7,
      Swearing8,
      Swearing9,
    ],
  },
  {
    type: "activities-acm-sts",
    images: [ImgActivities1, ImgActivities2, ImgActivities3, ImgActivities4, ImgActivities5, ImgActivities6, ImgActivities7, ImgActivities8, ImgActivities9, ImgCampusAdministrators],
  },
  {
    type: "works-acm-sts",
    images: [ImgWorks1, ImgWorks2, ImgCampusAdministrators, ImgBlankImage],
  }
];
